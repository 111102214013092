@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-display: swap;
  font-weight: 200;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 800;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
}
